exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-pages-blog-another-post-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/bruce/Documents/GitHub/gatsby_tutorial/src/pages/blog/another-post/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-pages-blog-another-post-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-pages-blog-my-favorite-pet-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/bruce/Documents/GitHub/gatsby_tutorial/src/pages/blog/my-favorite-pet/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-pages-blog-my-favorite-pet-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-pages-blog-my-first-post-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/bruce/Documents/GitHub/gatsby_tutorial/src/pages/blog/my-first-post/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-pages-blog-my-first-post-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-val-comp-src-pages-armor-js": () => import("./../../../src/pages/val_comp/src/pages/armor.js" /* webpackChunkName: "component---src-pages-val-comp-src-pages-armor-js" */),
  "component---src-pages-val-comp-src-pages-farm-js": () => import("./../../../src/pages/val_comp/src/pages/farm.js" /* webpackChunkName: "component---src-pages-val-comp-src-pages-farm-js" */),
  "component---src-pages-val-comp-src-pages-food-js": () => import("./../../../src/pages/val_comp/src/pages/food.js" /* webpackChunkName: "component---src-pages-val-comp-src-pages-food-js" */),
  "component---src-pages-val-comp-src-pages-index-js": () => import("./../../../src/pages/val_comp/src/pages/index.js" /* webpackChunkName: "component---src-pages-val-comp-src-pages-index-js" */),
  "component---src-pages-val-comp-src-pages-mead-js": () => import("./../../../src/pages/val_comp/src/pages/mead.js" /* webpackChunkName: "component---src-pages-val-comp-src-pages-mead-js" */),
  "component---src-pages-val-comp-src-pages-tools-js": () => import("./../../../src/pages/val_comp/src/pages/tools.js" /* webpackChunkName: "component---src-pages-val-comp-src-pages-tools-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */)
}

